import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import SectionTitle from "../SectionTitle";
import GridContainer from "../GridContainer";
import { breakpoints, colors } from "../../styles/variables";
import imgChar from "../../images/hero/fortune_hero.png";
import imgBomb from "../../images/hero/charCrab.png";

const Section = styled.section`
  position: relative;
  background-color: ${colors.darkGreen};
  padding-bottom: 11vw;
  .imageChar {
    position: absolute;
    bottom: 90%;
    left: 10%;
    width: 30%;
    max-width: 450px;
    padding-top: 25%;
    background: center center / contain no-repeat;
    background-image: url(${imgChar});
    pointer-events: none;
  }
  .imageBomb {
    position: absolute;
    bottom: 50%;
    right: 10%;
    width: 15%;
    max-width: 300px;
    padding-top: 15%;
    background: center center / contain no-repeat;
    background-image: url(${imgBomb});
    transform: rotate(45deg);
    opacity: 0.85;
  }
  .gridOut {
    height: 100%;
  }
  .gridIn {
    grid-column: 4 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .buttonRow {
    text-align: center;
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridIn {
      grid-column: 3 / span 4;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .gridIn {
      grid-column: 1 / span 2;
    }
  }
`;

const data = {
  title: "Media Inquiries",
  description:
    "In quis mi rhoncus, euismod neque non, tristique nisl. Maecenas porta dui in justo cursus, lacinia tincidunt odio auctor. Fusce quam velit, finibus at lorem vitae, ornare tempor nulla. ",
  button_text: "Contact us",
  click_url: "https://www.google.com",
};

export const fragment = graphql`
  fragment MediaContactFragment on Contentstack_casper_media_page {
    mediaContact: media_page_contact_section {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`;

export interface MediaInquiriesData {
  alignment: string;
  headline: string;
  bodyCopy: string;
  ctaLink: {
    title: string;
    href: string;
  };
}

interface Props {
  data: MediaInquiriesData;
}

const MediaInquiries = (props: Props) => {
  const { alignment, headline, bodyCopy, ctaLink } = props.data;

  return (
    <Section>
      {/* <div className="imageChar"></div>
      <div className="imageBomb"></div> */}
      <GridContainer className="gridOut">
        <div className="gridIn">
          <SectionTitle colorTheme="light" title={headline}>
            {bodyCopy}
          </SectionTitle>
          <div className="buttonRow">
            <ButtonText
              colorTheme="teal"
              callback={() => {
                window.open(ctaLink.href, "_new");
              }}
            >
              {ctaLink.title}
            </ButtonText>
          </div>
        </div>
      </GridContainer>
    </Section>
  );
};

export default MediaInquiries;
