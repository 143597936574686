import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import GridContainer from "../GridContainer";
import SectionTitle from "../SectionTitle";
import VideoPreview from "../VideoPreview";
import { breakpoints, colors, eases } from "../../styles/variables";

import imgBackground from "../../images/backgroundGallery.jpg";
import HeroVideoImage from "../images/hero-video-thumbnail.png";

export const fragment = graphql`
  fragment MediaMarqueeFragment on Contentstack_casper_media_page {
    mediaMarquee: media_marquee_section {
      headline
      flavorCopy: flavor_copy
      descriptionCopy: description_copy
      legalCopy: legal_copy
      marqueeCta: marquee_cta {
        title
        href
      }
      youtubeEmbedLink: youtube_embed_link {
        title
        href
      }
      videoThumb: video_thumb {
        url
      }
      inlineVideoFile: inline_video_file {
        url
      }
      videoDescriptor: video_descriptor
    }
  }
`;

export interface MediaHeroData {
  headline: string;
  flavorCopy: string;
  descriptionCopy: string;
  legalCopy: string;
  marqueeCta: {
    title: string;
    href: string;
  };
  youtubeEmbedLink: {
    title: string;
    href: string;
  };
  videoThumb: {
    url: string;
  };
  inlineVideoFile: {
    url: string;
  };
  videoDescriptor: string;
}

interface Props {
  data: MediaHeroData;
  videoOpen: Function;
}

interface Props {
  videoOpen: Function;
}

export default function MediaHero(props: Props) {
  const {
    headline: title,
    flavorCopy: subTitle,
    descriptionCopy: description,
    legalCopy: cta_subtext,
    marqueeCta: { title: cta_text, href: cta_href },
    videoThumb: { url: thumbImageURL },
    inlineVideoFile: { url: thumbVideoURL },
    videoDescriptor: videoText,
  } = props.data;

  // TODO: Must link to full ZIP
  const downloadAll = () => {
    window.open(cta_href, "_new");
  };

  return (
    <Section className="lazyload" style={{ backgroundImage: `url("${imgBackground}")` }}>
      <GridContainer className="gridOut">
        <div className="text-header">{title}</div>
        <div className="text-miniheader">{subTitle}</div>
        <div className="videoBox">
          <VideoPreview
            videoOpen={props.videoOpen}
            thumbnailImage={thumbImageURL}
            videoURL={thumbVideoURL}
            textCaption={videoText}
            className="videoCustom"
          ></VideoPreview>
        </div>
        <div className="bigParagraph">
          <div className="text-paragraph" dangerouslySetInnerHTML={{ __html: description }} />
          {/* <ButtonText className="downloadBtn" colorTheme="teal" callback={ downloadAll }>{cta_text}</ButtonText> */}
          <div className="text-paragraph">{cta_subtext}</div>
        </div>
      </GridContainer>
    </Section>
  );
}

const Section = styled.section`
  // background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 11.5vw;
  margin-bottom: -11.5vw;
  .gridOut {
    padding-top: 100px;
  }

  a {
    color: ${colors.white};
  }

  .text-header {
    grid-column: 1 / span 6;
    color: ${colors.white};
    text-align: left;
  }
  .text-miniheader {
    grid-row: 2 / span 1;
    grid-column: 1 / span 6;
    margin-top: 0.5em;
    color: ${colors.white};
    text-align: left;
    text-transform: uppercase;
  }
  .downloadBtn {
    margin: 32px 0;
  }
  .videoBox {
    grid-column: 8 / span 5;
    grid-row: 1 / span 3;
  }
  .videoCustom {
    width: 100%;
    max-width: 100%;
  }
  .bigParagraph {
    grid-column: 2 / span 5;
    grid-row: 3 / span 3;
    margin-top: 64px;
    color: ${colors.white};
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridOut {
      padding-top: 50px;
    }
    .videoBox {
      grid-column: 1 / span 8;
      grid-row: initial;
      max-width: 100%;
      margin: 32px 0;
    }
    .videoCustom {
      width: 500px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .bigParagraph {
      margin-top: 0;
      grid-row: initial;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .gridOut {
      padding-top: 24px;
    }
    .text-header,
    .text-miniheader,
    .videoBox,
    .bigParagraph {
      grid-column: 1 / span 2;
    }
  }
`;
